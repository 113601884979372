/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable simple-import-sort/imports */
import {
    CmsPageComponent as SourceCmsPage,
} from 'SourceRoute/CmsPage/CmsPage.component';

import './CmsPage.override.style';

/** @namespace Scandipwa/Route/CmsPage/Component */
export class CmsPageComponent extends SourceCmsPage {

}

export default CmsPageComponent;
